import { ThemeProvider } from "emotion-theming";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "sanitize.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { theme } from "./theme";
import GlobalStyles from "./theme/GlobalStyles";

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    <Router>
      <App />
    </Router>
  </ThemeProvider>,
  document.getElementById("root"),
);

serviceWorker.unregister();
