/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { typography, TypographyProps as TypographyPropsStyled } from "styled-system";
import { AsType, BoxComponent, BoxComponentProps, BoxPassedProps, BoxProps } from ".";
import styled, { Theme } from "../../theme";

export type TypographyProps = TypographyPropsStyled & BoxProps;
const TypographyComponent = styled(BoxComponent)<BoxProps & TypographyProps>`
  ${typography}
`;
export const Typography = <T extends AsType = "p">(props: BoxComponentProps<T> & TypographyProps) => (
  <TypographyComponent {...{ as: "p" }} {...(props as BoxPassedProps & TypographyProps)} />
);

type HeadingProps = TypographyProps & {
  fullUnderline?: boolean;
};
export const Heading: React.FC<HeadingProps> = ({ fullUnderline, ...props }) => (
  <Typography
    as="h2"
    fontSize={44}
    fontWeight="500"
    {...props}
    css={(theme: Theme) => css`
      position: relative;

      ${fullUnderline &&
        css`
          text-align: center;
        `}

      &::before {
        content: " ";
        position: absolute;
        bottom: 0;
        border-bottom: 4px solid ${theme.colors.secondary};
        ${fullUnderline &&
          css`
            left: 0;
            right: 0;
          `}
        ${!fullUnderline &&
          css`
            width: 60px;
          `}
      }
    `}
  />
);

export const PageTitle: React.FC<TypographyProps> = props => (
  <Typography
    as="h2"
    fontSize={44}
    {...props}
    css={(theme: Theme) => css`
      font-size: 28px;
      line-height: 28px;
      color: ${theme.colors.primary};
      font-weight: 700;
      letter-spacing: -1px;
    `}
  >
    Share My Room
  </Typography>
);
