import { css } from "@emotion/core";
import React, { Component, FC, PropsWithChildren } from "react";
import {
  color,
  ColorProps,
  compose,
  flexbox,
  FlexboxProps,
  grid,
  GridProps as GridPropsStyled,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  style,
} from "styled-system";
import useScrollbarWidth from "../../hooks/useScrollbarWidth";
import styled, { Theme } from "../../theme";

export const textColor = style({
  /* stylelint-disable-next-line property-no-unknown */
  prop: "textColor",
  /* stylelint-disable-next-line property-no-unknown */
  cssProperty: "color",
  /* stylelint-disable-next-line property-no-unknown */
  key: "colors",
});

type CustomProps = {
  textColor?: ColorProps<Theme>["color"];
  bodyWidth?: boolean;
} & (
  | {
      fullWidth?: false;
    }
  | {
      fullWidth: true;
      scrollbarWidth: number;
    }
);

// FIXME: Because of `FC<any>` tag types have broken typecheck
export type AsType = keyof JSX.IntrinsicElements | FC<any> | typeof Component;
export type AsProps<T extends AsType> = {
  as?: T;
} & (T extends FC<infer FProps>
  ? FProps
  : T extends new (...args: any) => Component<infer CProps>
  ? CProps
  : T extends keyof JSX.IntrinsicElements
  ? JSX.IntrinsicElements[T]
  : never);

export type BoxComponentProps<T extends AsType> = PropsWithChildren<BoxProps> & AsProps<T>;
export type BoxPassedProps = PropsWithChildren<BoxProps>;

type FlexSelfProps = Pick<FlexboxProps, "flex" | "flexGrow" | "flexShrink" | "flexBasis" | "justifySelf" | "alignSelf" | "order">;
type GridSelfProps = Pick<GridPropsStyled, "gridColumn" | "gridRow" | "gridArea">;

export type BoxProps = SpaceProps & Omit<ColorProps<Theme>, "color"> & CustomProps & LayoutProps & FlexSelfProps & GridSelfProps;
export const BoxComponent = styled.div<BoxProps>`
  ${compose(space, color, textColor, layout, flexbox, grid)}
  ${p =>
    p.fullWidth &&
    css`
      width: calc(100vw - ${p.scrollbarWidth}px);
      position: relative;
      left: 50%;
      margin-left: calc(-50vw + ${p.scrollbarWidth / 2}px);
    `};
  ${p =>
    p.bodyWidth &&
    css`
      padding-left: ${p.theme.space[4]}px;
      padding-right: ${p.theme.space[4]}px;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-width: ${p.theme.constants.bodyWidth};
    `};
`;
export const Box = <T extends AsType = "div">(props: BoxComponentProps<T>) => <BoxComponent {...(props as BoxPassedProps)} />;
export const Flex = <T extends AsType = "div">(props: BoxComponentProps<T> & FlexboxProps) => (
  <BoxComponent display="flex" {...(props as BoxPassedProps & FlexboxProps)} />
);
export const Grid = <T extends AsType = "div">(props: BoxComponentProps<T> & GridPropsStyled) => (
  <BoxComponent display="grid" {...(props as BoxPassedProps & GridPropsStyled)} />
);
export const FullWidth = <T extends AsType = "div">(props: BoxComponentProps<T>) => {
  return <BoxComponent {...(props as BoxPassedProps)} fullWidth scrollbarWidth={useScrollbarWidth()} />;
};
