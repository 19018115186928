import { RouteProps } from "react-router-dom";
import LazyRoute from "./LazyRoute";

type RouteItem = Required<Pick<RouteProps, "path" | "render">>;

export const Routes = {
  Home: "/",
  Accommodations: "/accommodations",
  Detail: (id: string) => `/accommodations/${id}`,
};

const routes: RouteItem[] = [
  {
    path: Routes.Home,
    render: LazyRoute(() => import("../pages/home")),
  },
  {
    path: Routes.Accommodations,
    render: LazyRoute(() => import("../pages/accommodations")),
  },
  {
    path: Routes.Detail(":id"),
    render: LazyRoute(() => import("../pages/accommodations/detail")),
  },
];
export default routes;
