import { RefObject, useEffect, useState } from "react";

/**
 * Checks scrollbars on given ref or the whole window if no ref is provided
 * @param ref Element to check
 */
const useScrollbarWidth = (ref?: RefObject<HTMLElement | undefined>) => {
  const [width, setWidth] = useState(0);

  const innerWidth = ref?.current?.scrollWidth ?? window.innerWidth;
  const clientWidth = ref?.current?.clientWidth ?? document.body.clientWidth;

  useEffect(
    () => {
      width === 0 && setWidth((ref?.current?.scrollWidth ?? window.innerWidth) - (ref?.current?.clientWidth ?? document.body.clientWidth));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [innerWidth, clientWidth, width],
  );

  return width;
};
export default useScrollbarWidth;
