/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { FlexboxProps } from "styled-system";
import { AsType, BoxComponentProps, Flex } from ".";
import { Theme } from "../../theme";

type Props = {
  variant?: "filled" | "inline";
  color?: keyof Theme["colors"];
  active?: boolean;
  disabled?: boolean;
};

export const Button = <T extends AsType = "button">(p: BoxComponentProps<T> & FlexboxProps & Props) => {
  // FIXME: Type inference is not workign properly here
  const { variant, active, disabled, color, ...props } = p as Props;
  return (
    <Flex
      as="button"
      type="button"
      px={4}
      py={3}
      {...props}
      css={(theme: Theme) => css`
        align-items: center;
        justify-content: center;
        font-size: 16px;
        border: none;
        border-radius: 27.5px;
        background: none;
        text-decoration: none;
        cursor: pointer;

        /* Filled variant */
        ${(!variant || variant === "filled") &&
          css`
            background-color: ${theme.colors[color ?? "primary"]};
            color: white;
          `}

        /* Inline variant */
        ${variant === "inline" &&
          css`
            color: ${theme.colors[color ?? "textPrimary"]};
            padding: 0;

            &:hover {
              text-decoration: underline;
            }

            ${active &&
              css`
                font-weight: bold;
              `}
            ${disabled &&
              css`
                color: ${theme.colors.textLight};
              `}
          `}
      `}
    />
  );
};
