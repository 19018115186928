/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { NavLink } from "react-router-dom";
import { Routes } from "../routes";
import { Theme } from "../theme";
import { Box, Flex } from "./styled";
import { Button } from "./styled/Button";
import { PageTitle } from "./styled/Typography";

type Props = {
  items: {
    name: string;
    to: string;
  }[];
};

// TODO: Responsivness
const Header: React.FC<Props> = ({ items }) => (
  <Box>
    <Flex
      as={"header" as const}
      alignItems="center"
      mx="auto"
      p={4}
      css={(theme: Theme) => css`
        max-width: ${theme.constants.bodyWidth};
      `}
    >
      <Button as={NavLink} to={Routes.Home} variant="inline">
        <PageTitle />
      </Button>
      <Box flexGrow={1} />
      {items.map(i => (
        <Button key={i.name} as={NavLink} to={i.to} variant="inline" ml={3}>
          {i.name}
        </Button>
      ))}
    </Flex>
  </Box>
);
export default Header;
