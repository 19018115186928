import React from "react";
import { Route, Switch } from "react-router-dom";
import Header from "./components/Header";
import { Flex } from "./components/styled";
import useScrollToTop from "./hooks/useScrollToTop";
import routes, { Routes } from "./routes";
import LazyRoute from "./routes/LazyRoute";

const HeaderItems = [
  {
    name: "List of accommodation",
    to: Routes.Accommodations,
  },
  {
    name: "About us",
    to: "/about",
  },
  {
    name: "FAQs",
    to: "/faq",
  },
  {
    name: "Contact",
    to: "/contact",
  },
];

const App: React.FC = () => {
  useScrollToTop();
  return (
    <Flex as="main" flexDirection="column" minHeight="100vh">
      <Header items={HeaderItems} />
      <Flex flexDirection="column" flexGrow={1} py={3} bodyWidth>
        <Switch>
          {routes.map(route => (
            <Route key={route.path.toString()} exact path={route.path} render={route.render} />
          ))}
          <Route exact render={LazyRoute(() => import("./pages/notFound"))} />
        </Switch>
      </Flex>
    </Flex>
  );
};

export default App;
